import * as React from "react";
import * as ReactDOM from "react-dom";
import axios from "axios";
import MediaCard from "./components/card";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import type { CourseObjectType } from "./types/types";
import BackToTop from "./components/BacktoTop";

// holds per-page list of courses
// Under key 0 there will be a list of courses for the first page

const course_lists: any = {};

const App = () => {
  const [isLoading, setIsLoading] = React.useState(true);
  const [currentPage, setCurrentPage] = React.useState(1);
  /*  const [totalPages, setTotalPages] = useState(0); */

  const update_data = async function () {
    if (typeof course_lists[currentPage] == "undefined") {
      setIsLoading(true);
      const result = await axios("/api/courses/v1/courses/", {
        params: { page_size: 100 },
      });
      course_lists[currentPage] = result.data.results;
      /* setTotalPages(result.data.pagination.num_pages); */
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    update_data();
  });

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1300 },
      items: 3,
      slidesToSlide: 3, // optional, default to 1.
    },
    tabletLandscape: {
      breakpoint: { max: 1300, min: 991 },
      items: 3,
      slidesToSlide: 2, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 991, min: 464 },
      items: 2,
      slidesToSlide: 2, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };

  return (
    <React.Fragment>
      <section className="highlighted-courses">
        <section className="courses">
          <h1>Discover</h1>
          <ul className="journal-list" />
          <ul className="journal-list" />
          {isLoading ? (
            <div>Loading ...</div>
          ) : (
            <div className="courses-listing courses-list">
              <Carousel responsive={responsive}>
                {course_lists[currentPage].map(
                  (courseInfo: CourseObjectType) => (
                    <MediaCard key={courseInfo.id} courseInfo={courseInfo} />
                  )
                )}
              </Carousel>
            </div>
          )}
        </section>
      </section>
    </React.Fragment>
  );
};
ReactDOM.render(
  <React.StrictMode>
    <BackToTop />
  </React.StrictMode>,
  document.getElementById("backToTop")
);

const someElement = document.getElementById("courses-container");
if (someElement) {
  ReactDOM.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    someElement
  );
}
