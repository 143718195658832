import * as React from "react";
import type { CourseObjectType } from "../types/types";
// @ts-ignore
import * as logo from "../images/at-arrow-white.png";
// @ts-ignore
import * as Redlogo from "../images/at-arrow-gradient.png";

const MediaCard = ({ courseInfo }: { courseInfo: CourseObjectType }) => {
  return (
    <div className="courses-listing-item">
      <article className="course" id={courseInfo.course_id}>
        <a href={"/courses/" + courseInfo.course_id + "/about"}>
          <header className="course-image">
            <div className="cover-image">
              <img src={courseInfo.media.image.large} alt={courseInfo.name} />
              <img src={logo} alt={courseInfo.name} />

              <div className="learn-more" aria-hidden="true">
                Learn more
              </div>
            </div>
          </header>
          <div className="course-info" aria-hidden="true">
            <h2 className="course-name">
              <span className="course-organization">{courseInfo.org}</span>
              <span className="course-code">{courseInfo.number}</span>
              <span className="course-title">{courseInfo.name}</span>
              <img
                className="course-arrow"
                src={Redlogo}
                alt={courseInfo.name}
              />
            </h2>
            <div
              className="course-date localized_datetime"
              aria-hidden="true"
              data-format="shortDate"
              data-datetime={courseInfo.start}
              data-language="de-de"
              data-string="Starts: {date}"
            >
              Starts: {courseInfo.start_display}
            </div>
          </div>
          <div className="sr">
            <ul>
              <li>example</li>
              <li>Anfanger-IT-84fe56</li>
              <li>
                Starts:{" "}
                <time
                  className="localized_datetime"
                  itemProp="startDate"
                  data-format="shortDate"
                  data-datetime={courseInfo.start}
                  data-language="de-de"
                >
                  {courseInfo.start_display}
                </time>
              </li>
            </ul>
          </div>
        </a>
      </article>
    </div>
  );
};

export default MediaCard;
